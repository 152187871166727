import { useState, useEffect, Fragment } from 'react';
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import apiClient from '../../utils/API';
import { Alert } from '@material-ui/core';

/**
 * description of award offer or authorization
 * 
 * @returns {Component}
 */
function AwardSummary({ program, awards, application }) {

    const app = new ApplicationInfo(application);

    if (awards == null || awards.length === 0)
        return null;

    const isCollegeDisplayed = true; //uniq(awards.map(x => x.CollegeId)).length > 1;
    const awardNotes = awards[0].Notes;
    awardNotes.sort((a, b) => a.Order - b.Order);

    /**
     * 
     * @param {*} item 
     * @param {*} i 
     * @returns 
     */
    
    function AwardRow({ item, i }) {

        const [college, setCollege] = useState(null);
        const cleanStatuses = item.Statuses.filter(status => status.StatusDescription !== null && status.StatusDescription !== '' && status.StatusDescription !== 'Renewed');
        const isRejectReason = item.Statuses.some(status => status.CodeType === 'Student_OrpRejectCode');
        let showItem = (app.isRenewal === false) || (app.isRenewal && item.SummaryStatus === "Authorized")? true : false;
        useEffect(() => {
            (async () => {
                const college = await apiClient.getCollege(item.CollegeId);
                setCollege(college);
            })();
        }, []);

        return (
            <Fragment>
                {showItem &&
                    <tr key={i}>
                        <td style={{ whiteSpace: 'nowrap' }} >{item.Term} {item.Year}</td>
                        <td>${parseFloat(item.AuthorizedAmount ? item.AuthorizedAmount : 0.00).toFixed(2)}</td>
                        {(app.isAwarded || app.isRenewal) &&
                            <td>${parseFloat(item.DisbursedAmount ? item.DisbursedAmount : 0.00).toFixed(2)}</td>
                        }
                        {isCollegeDisplayed &&
                            <td>
                                {college?.ShortName}
                            </td>
                        }
                    </tr>
                }
                {cleanStatuses.length > 0 &&
                    <tr>
                        <td></td>
                        <td colspan={((app.isAwarded || app.isRenewal)? 2 : 1) + (isCollegeDisplayed ? 1 : 0)}>
                            {isRejectReason &&
                                <p><strong>Reject Reasons</strong></p>
                            }
                            <ul className="list">
                                {cleanStatuses.map((status, x) =>
                                    <li className="li" key={x} dangerouslySetInnerHTML={{ __html: status.StatusDescription }} />
                                )}
                            </ul>
                        </td>
                    </tr>
                }
            </Fragment>

        );
    }

    return (

        <div>

            <table>
                <thead>
                    <tr>
                        <th>Term</th>
                        <th>
                            {app.isAwardOffer && !app.isRenewal? 'Offer' : 'Tentative'}
                        </th>
                        {(app.isAwarded || app.isRenewal) &&
                            <th>Disbursed</th>
                        }
                        <th>College</th>
                    </tr>
                </thead>
                <tbody>
                    {awards.map((item, i) => <AwardRow item={item} i={i} />)}
                </tbody>
            </table>

            {(app.isAwardOffer || app.isAwarded) && app.programCode !== 'ORP' && !app.isRenewal &&
                <p style={{ marginBottom: '1.5em' }}>
                    Be sure to update your
                    {" "}<a href={`/additional-scholarship-info/${app.year}#college-choice`}>college</a>,
                    {" "}<a href={`/additional-scholarship-info/${app.year}#college-plans`}>majors</a>,
                    {" "}and
                    {" "}<a href="/profile#contact-info">contact info</a> to receive your award.
                </p>
            }

            {(app.isAwardOffer || app.isAwarded) && app.programCode !== 'ORP' && app.isRenewal &&
                <p style={{ marginBottom: '1.5em' }}>
                    Be sure to update your
                    {" "}<a href={`/renew/${app.programCode}/${app.year}`}>college</a>,
                    {" "}<a href={`/renew/${app.programCode}/${app.year}`}>majors</a>,
                    {" "}and
                    {" "}<a href="/profile#contact-info">contact info</a> to receive your award.
                </p>
            }


            {awardNotes.length > 0 &&
               <Alert className="rhythm" variant="outlined" severity="info"> 
                    <h3 className="heading heading--h3" style={{marginBottom: '1em'}}>Terms & Conditions</h3>
                    <ul className={awardNotes.length > 1 ? "list" : ""}>
                        {awardNotes.map((item, i) =>
                            <li key={i}>
                                {item.Category &&
                                    <>
                                        <strong>{item.Category}</strong><br />
                                    </>
                                }

                                <div className='richtext' dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                            </li>
                        )}
                    </ul>
                </Alert>
            }


        </div>

    );
}

export default AwardSummary;