import { useSelector } from "react-redux";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PortalView from "./../../components/Global/PortalView";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import { goBack } from "../../utils";
import Question from "../../utils/dtos/Question";
import { useHistory, useParams } from "react-router-dom";
import { FieldSlot, FieldSection, FieldRow, } from "../../components/Forms/FieldSection";
import SpinnerSection from "../../components/Utility/SpinnerSection";
import { actions } from "../../store";
import striptags from "striptags";
import LargeTextEditor from "../../components/LargeTextEditor";
import Checkbox from "../../components/Checkbox";
import DecimalAnswer from "../../components/answer/DecimalAnswer";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import ScholarshipUploader from "../../components/ScholarshipUploader";
import { compositeFieldname } from '../../utils';
import { AnswerTypeDocumentProps, DocumentDeliveryTypes, FIELDS } from '../../store/form/fields';
import HiddenInput from '../../components/Utility/HiddenInput';
import EditCollegeOfChoice from "../field-groups/EditCollegeOfChoice";
import RenewEditCollegePlan from "./RenewEditCollegePlan";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import SectionFormHelper from "../../utils/helper/SectionHelper";

function Renew() {
    const methods = useForm({ mode: "onBlur", });
    const { errors, formState, watch, handleSubmit, getValues } = methods;

    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };
    const { code } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [programData, setProgramData] = useState(null);
    const [questionData, setQuestionData] = useState([]);
    const program = new ProgramInfo(programData);
    const activeYear = program.currentYear;
    const year = activeYear;

    var sections = new SectionFormHelper(useState(null));
    var collegeChoiceSection = sections.create(useState(null), null, [
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING,
        FIELDS.COLLEGE_DUAL_ENROLLED
    ]);
    var collegePlansSection = sections.create(useState(null), null, [
        FIELDS.DEGREES,
        FIELDS.UNDERGRAD_GPA,
        FIELDS.GRADUATE_GPA
    ]);
    var scholarshipQuestions = sections.create(useState(null), null, questionData.map(x => x.Name));

    useEffect(() => {
        (async () => {
            try {
                const programOverview = await apiClient.getProgram(code);
                const program = new ProgramInfo(programOverview);
                const questions = await apiClient.get(`question/questions/${code}/${program.currentYear}`);        
                setProgramData(programOverview);
                setQuestionData(questions);
                
                
                scholarshipQuestions._fieldKeys = questions.map(x => x.Name);
                sections.fetchAnswers(program.currentYear);

            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);


    /**
     * actual answer submit handler
     * @param {*} data formdata
     */
    const onSubmit = async (data) => {
        try {

            setSaveLoading(true)
            if (watchNoLetterGrades === true) {
                data[FIELDS.UNDERGRAD_GPA] = "0.01"
            }

            const results = await actions.submitForm(data, [], ["Agreement"], activeYear);
            await apiClient.acceptApplicationAward(code, year);
            // console.log(data, e);
            setShowConfirmation(true);
            dispatch(
                open({
                    message: "Saved Successfully",
                    severity: "success",
                })
            );

        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSaveLoading(false)
        }
    };

    const onError = (errors, e) => {
        console.log({
            errors,
            e,
        });
        if(errors){
            dispatch(open({
              message: "Please take a moment to complete the required fields.",
              severity: "success",
            }))
        }
    };



    const watchNoLetterGrades = watch("NoLetterGrades")
   
    const setShowConfirmation = () => {
        history.push("/dashboard");
    };

    //=====( VALIDATE GPA )

    const [gpaError, setGpaError] = useState(null);
    const validateGPA = () => {
        if (getValues(FIELDS.GRADUATE_GPA) || getValues(FIELDS.UNDERGRAD_GPA)) {
            setGpaError(null);
            return true;
        } else {
            setGpaError(
                <div style={{ marginTop: ".75rem", color: "red" }}>
                    * One GPA Field Is Required
                </div>
            );
            return false;
        }
    };

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage" className="Scholarships">
                    <div className="rhythm">
                        <div className="page-intro">
                            <div className="wrapper">
                                <div className="page-intro__layout">
                                    <div className="page-intro__icon">
                                        <img src="/MyApplictionsIcon.svg" alt="Student Portal" style={{ marginTop: ".25rem" }} />
                                    </div>
                                    <div className="page-intro__content rhythm">
                                        <h1 className="heading heading--h1">{program.name}</h1>
                                        <a href="/dashboard">Return to Dashboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && <SpinnerSection />}
                        {!isLoading && (
                            <div className="wrapper rhythm">

                                <div className="form-wrapper">
                                    <h2 className="heading heading--h2">Scholarship Renewal</h2>
                                    <div className="component">
                                        <>
                                            {program.currentSeason?.notes.map((note) => (
                                                <FieldRow>
                                                    <FieldSlot>
                                                        <strong>{note.Category}</strong>
                                                    </FieldSlot>
                                                    <FieldSlot>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: note.Description,
                                                            }}
                                                        ></span>
                                                    </FieldSlot>
                                                </FieldRow>
                                            ))}
                                        </>
                                    </div>
 
                                    <EditCollegeOfChoice year={year} hideDualEnrolled={false} section={collegeChoiceSection} />

                                    <RenewEditCollegePlan section={collegePlansSection} />

                                    <HiddenInput name={"validateGpa"} rules={{ validate: () => validateGPA() }} />
                                    <FieldSection isSoftRequired={true}
                                    title="Cumulative Undergraduate GPA" withDivider={true}>
                                        <FieldRow>
                                            <FieldSlot>
                                                <DecimalAnswer disabled={watchNoLetterGrades} answer={collegePlansSection.getAnswerByField(FIELDS.UNDERGRAD_GPA)} inputLabel="Undergraduate GPA" />
                                                {gpaError}
                                            </FieldSlot>
                                            <FieldSlot container alignItems="flex-end">
                                                <Checkbox name='NoLetterGrades' label="My college did not assign grades." />
                                            </FieldSlot>
                                        </FieldRow>
                                    </FieldSection>
                                    <FieldSection isSoftRequired={true}
                                    title="Cumulative Graduate GPA" withDivider={true}>
                                        <FieldRow>
                                            <FieldSlot>
                                                <DecimalAnswer answer={collegePlansSection.getAnswerByField(FIELDS.GRADUATE_GPA)} inputLabel="Graduate GPA" />
                                                {gpaError}
                                            </FieldSlot>
                                        </FieldRow>
                                    </FieldSection>

                                    <div className="component">
                                        {questionData.map((questionData, index) => {
                                            let question = new Question(questionData);
                                            if (questionData.Stage == 'Renewal') {
                                                let { Name, Type, Title, Description } = questionData;
                                                let fieldComponent = null;

                                                const isLastItem = questionData.length === index - 1;

                                                switch (Type) {
                                                    case "Text":
                                                        fieldComponent = (
                                                            <FieldSection
                                                                title={Title}
                                                                subtitle={Description}
                                                                withDivider={!isLastItem}
                                                            >
                                                                <FieldRow>
                                                                    <FieldSlot md={12}>
                                                                        <LargeTextEditor
                                                                            name={Name}
                                                                            inputLabel={Title}
                                                                            placeholder={"Start writing here..."}
                                                                            wordLimit="10000"
                                                                        />
                                                                    </FieldSlot>
                                                                </FieldRow>
                                                            </FieldSection>
                                                        );
                                                        break;
                                                    case "Boolean":
                                                        fieldComponent = (
                                                            <FieldSection
                                                                title={Title}
                                                                withDivider={!isLastItem}
                                                            >
                                                                <Checkbox name={Name} label={striptags(Description)} />{" "}
                                                            </FieldSection>
                                                        );
                                                        break;                                                   
                                                    case "Document":
                                                        fieldComponent = (
                                                            <FieldSection title={Title} withDivider={!isLastItem} subtitle={Description}>
                                                                <FieldRow>
                                                                    <FieldSlot>
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${Name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.ID)} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${Name}_${DocumentDeliveryTypes.UPLOAD}`, "AnswerType")} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${Name}_${DocumentDeliveryTypes.UPLOAD}`, "IsScholarshipDocument")}
                                                                            defaultValue={true} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${Name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.DELIVERY_TYPE)}
                                                                            defaultValue={DocumentDeliveryTypes.UPLOAD} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${Name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.IS_SELECTED)}
                                                                            defaultValue={true} />
                                                                        <ScholarshipUploader name={`${Name}_${DocumentDeliveryTypes.UPLOAD}`}
                                                                            isRequired={question.isRequired}></ScholarshipUploader>
                                                                    </FieldSlot>
                                                                </FieldRow>
                                                            </FieldSection>
                                                        );
                                                    default:
                                                        break;
                                                }
                                                return (
                                                    <div style={{ marginTop: "2rem" }}>
                                                        {fieldComponent}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>

                                    <div
                                        container
                                        className="grant-action-buttons"
                                        marginTop="1rem"
                                    >

                                        <div>
                                            <Button
                                                loading={saveLoading}
                                                name="Renew"
                                                label="Renew"
                                                type="submit"
                                                onClick={handleSubmit(onSubmit, onError)}
                                            />
                                            <Button
                                                name="Cancel"
                                                label="Cancel"
                                                variant="text"
                                                onClick={handleCancel}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default Renew;
