import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PortalView from "./../../components/Global/PortalView";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import Question from "../../utils/dtos/Question";
import { goBack } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import { FieldSlot, FieldSection, FieldRow, } from "../../components/Forms/FieldSection";
import SpinnerSection from "../../components/Utility/SpinnerSection";
import { actions } from "../../store";
import striptags from "striptags";
import LargeTextEditor from "../../components/LargeTextEditor";
import Checkbox from "../../components/Checkbox";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import ScholarshipUploader from "../../components/ScholarshipUploader";
import { compositeFieldname } from '../../utils';
import { AnswerTypeDocumentProps, AnswerTypeMemberProps, DocumentDeliveryTypes } from '../../store/form/fields';
import HiddenInput from '../../components/Utility/HiddenInput';
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import RadioGroup from "../../components/RadioGroup";
import AnswerSet from "../../utils/dtos/AnswerSet";
import MembershipAnswer from "../../components/answer/MembershipAnswer";
import ScholarshipProgressHeader from "../../components/application/ScholarshipProgressHeader";

/**
 * details page for individual scholarship
 * 
 * @returns {Component}
 */
function Scholarship() {

    const { code, year } = useParams();
    const activeYear = parseInt(year);

    const history = useHistory();
    const methods = useForm({
        mode: "onBlur",
    });
    const {
        errors,
        formState,
        watch,
        handleSubmit,
    } = methods;

    const [programData, setProgramData] = useState(null);
    const program = new ProgramInfo(programData);
    const seasonNotes = program.getSeason(activeYear)?.notes ?? [];
    seasonNotes.sort((a, b) => a.Order - b.Order);
    
    const [applicationData, setApplicationData] = useState(null);
    const appInfo = new ApplicationInfo(applicationData);

    const [questionData, setQuestionData] = useState([]);
    const questions = questionData.map(q => new Question(q));

    const [answerData, setAnswerData] = useState(null);
    const answers = new AnswerSet(answerData);

    const [requiredAnswers, setRequiredAnswers] = useState(null);
    const [completedSections, setCompletedSections] = useState(null);

    function processRequired(){
        let requiredQuestions = questions?.filter(q=> q.isRequired && q.stage?.toLowerCase() ===  "application").map(q=>{
            if(q.isRequired){
                return q.name;
            }
        })
        let completedAnswers = answers.getCompletedAnswers();
        let filteredAnswers = answers.getAnswers(requiredQuestions).filter(a => {
            // filter out other forms of delivery options for documents since uploads are only being asked for atm
            if(a.AnswerType?.toLowerCase() === "document" && a.DeliveryType?.toLowerCase() !== "upload"
            ){
                return null
            }
            else{
                return a
            }
        })

        let filteredCompletedAnswers = completedAnswers?.filter(a => {
            // match the filtered answers to the completed answers
            if(!filteredAnswers?.find(x => x.QuestionKey === a.QuestionKey)){
                return null
            }
            else{
                return a
            }
        })

        setRequiredAnswers(filteredAnswers)
        setCompletedSections(filteredCompletedAnswers)
    }

    useEffect(() => {
        if (appInfo) {
            processRequired();
        }
    }, [questionData,answerData]);

    const [isQualifiedByMember, setIsQualifiedByMember] = useState(null);

    /**
     * go back
     */
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };

    /**
     * remove scholarship action
     */
    const handleRemove = () => {
        if (!appInfo.isRemovable)
            return;
        (async () => {

            await apiClient.removeApplication(code, activeYear);

            //NOTE: technically approximate. We don't know 100% that the unapply succeeded
            setApplicationData({
                ...applicationData,
                IsStarted: false
            });
            dispatch(
                open({
                    message: "Scholarship removed.",
                    severity: "success",
                })
            );

        })();

    }
    

    const dispatch = useDispatch();
    const [saveLoading, setSaveLoading] = useState(false);
    const onSubmit = async (data) => {
        try {
            setSaveLoading(true);
            if (appInfo.isStarted === false) {
                await apiClient.startApplication(code, activeYear); // Start the application i.e., "IsStarted": true

                //NOTE: technically approximate. We don't _really_ know if IsStarted was succesful
                setApplicationData({
                    ...applicationData,
                    IsStarted: true
                });
            }
            const memberQuestion = getMembershipQuestion();
            if (memberQuestion){
                data[memberQuestion.name].IsQualifiedByMembership = isQualifiedByMember;
            }
            
            const results = await actions.submitForm(data, [], ["Agreement"], activeYear);
            dispatch(
                open({
                    message: "Saved Successfully",
                    severity: "success",
                })
            );

        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSaveLoading(false)
        }
    };

    const onError = (errors, e) => {
        console.log({
            errors,
            e,
        });
    };

    function getMembershipQuestion(){
        return questions.find(q => q.type == "Membership");
    }

    const [loading, setLoading] = useState(true);
    /**
     * initialize scholarship state
     */
    useEffect(() => {
        (async () => {
            if(loading === true || saveLoading === true) {
                const [programOverview, applicationData, questions] = await Promise.all([
                    apiClient.getProgram(code),
                    apiClient.getApplication(code, activeYear),
                    apiClient.get(`question/questions/${code}/${activeYear}`)
                ]);
                setProgramData(programOverview);
                setApplicationData(applicationData);
                setQuestionData(questions);

                if (questions.length == 0) 
                    return;
            
                const questionKeys = questions.map(q => q.Name);
                const answers = await actions.fetchBasicFields(questionKeys);
                setAnswerData(answers);
            
                const membershipQuestion = questions.map(q => new Question(q)).find(q => q.type == "Membership");
                if (membershipQuestion) {
                    if (membershipQuestion.isRequired)
                        setIsQualifiedByMember(true);
                    else {
                        var isQualifiedByMembership = new AnswerSet(answers).get(membershipQuestion?.name).IsQualifiedByMembership;
                        setIsQualifiedByMember(isQualifiedByMembership);
                    }
                }
                if (loading === true) {

                    setLoading(false);
                }
            }
        })();
    }, [loading,saveLoading]);

    const membershipQuestion = getMembershipQuestion();
    const qualifyByMemberField = membershipQuestion ? compositeFieldname(membershipQuestion.name, AnswerTypeMemberProps.QUALIFIED_BY_MEMBER) : null;
             
    const handleQualification = (e) => {    
        setIsQualifiedByMember(e.target.value === "True");
    }

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage" className="Scholarships">
                    <div className="rhythm">
                        <div className="page-intro">
                            <div className="wrapper">
                                <div className="page-intro__layout">
                                    <div className="page-intro__icon">
                                        <img src="/MyApplictionsIcon.svg" alt="Student Portal" style={{ marginTop: ".25rem" }} />
                                    </div>
                                    <div className="page-intro__content rhythm">
                                        <h1 className="heading heading--h1">{program.name}</h1>
                                        <a href="/profile">Return to Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {program.isLoading && <SpinnerSection />}
                        {!program.isLoading && (
                            <div className="wrapper rhythm">
                                <div className="form-wrapper">
                                    <h2 className="heading heading--h2">Scholarship</h2>
                                    {requiredAnswers?.length > 0?
                                        <ScholarshipProgressHeader program={program} answers={requiredAnswers} app={appInfo} completedSections={completedSections}/>
                                    :null}
                                    <div className="component">
                                        <>
                                            {seasonNotes.map((note) => (
                                                <FieldRow>
                                                    <FieldSlot>
                                                        <strong>{note.Category}</strong>
                                                    </FieldSlot>
                                                    <FieldSlot>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: note.Description,
                                                            }}
                                                        ></span>
                                                    </FieldSlot>
                                                </FieldRow>
                                            ))}
                                        </>
                                    </div>
                                    <div className="component">
                                        {membershipQuestion?.isEitherOr === true &&
                                            <FieldSection withDivider={false}
                                                title="Membership fund qualification">
                                                <FieldRow>
                                                    <FieldSlot md={12}>
                                                        <RadioGroup
                                                            onChange={handleQualification}
                                                            name={qualifyByMemberField}
                                                            value={isQualifiedByMember === true ? "True" : isQualifiedByMember === false ? "False" : ''}
                                                            listItems={[
                                                                { value: "True", label: "I am eligible for this scholarship through relationship to a qualifying member" },
                                                                { value: "False", label: "I am eligible through this scholarship by some other means", },
                                                            ]}
                                                        />
                                                    </FieldSlot>                                                
                                                </FieldRow>
                                            </FieldSection>
                                        }

                                        {questions.map((question, index) => {
                                            
                                            // if this is an alternative to a membership question, skip until implied we qualify by other means
                                            if (isQualifiedByMember !== false && question.type != "Membership" && question.requirementType == "EitherOr")
                                                return null;

                                            let fieldComponent = null;
                                            if ((question.isRenewalStage && appInfo.isRenewal) || (question.isApplicationStage && !appInfo.isRenewal)) {
                                                const isLastItem = questions.length === index - 1;                                                
                                                
                                                switch (question.type) {
                                                    case "Text":
                                                        fieldComponent = (
                                                            <FieldSection
                                                                title={question.title}
                                                                subtitle={question.description}
                                                                withDivider={!isLastItem}
                                                                isSoftRequired={question.isRequired}
                                                            >
                                                                <FieldRow>
                                                                    <FieldSlot md={12}>
                                                                        <LargeTextEditor
                                                                            name={question.name}
                                                                            inputLabel={question.title}
                                                                            placeholder={"Start writing here..."}
                                                                            characterLimit={question.maxCharacters}
                                                                        />
                                                                    </FieldSlot>
                                                                </FieldRow>
                                                            </FieldSection>
                                                        );
                                                        break;
                                                    case "Boolean":
                                                        fieldComponent = (
                                                            <FieldSection
                                                                title={question.title}
                                                                withDivider={!isLastItem}
                                                                isSoftRequired={question.isRequired}
                                                            >
                                                                <Checkbox name={question.name} label={striptags(question.description)} />{" "}
                                                            </FieldSection>
                                                        );
                                                        break;
                                                    case "Membership":
                                                        if (isQualifiedByMember)
                                                            fieldComponent = (
                                                                <FieldSection 
                                                                    title={question.title} 
                                                                    withDivider={!isLastItem}   
                                                                    isSoftRequired={question.isRequired}>
                                                                    <FieldRow>
                                                                        <FieldSlot>
                                                                            <MembershipAnswer question={question} answer={answers.get(question.name)} />
                                                                        </FieldSlot>
                                                                    </FieldRow>
                                                                </FieldSection>
                                                            );
                                                        else
                                                            fieldComponent = <></>
                                                        break;
                                                    case "Document":
                                                        const answer = answers.getDocument(question.name, DocumentDeliveryTypes.UPLOAD);
                                                        fieldComponent = (
                                                            <FieldSection title={question.title} withDivider={!isLastItem} subtitle={question.description} isSoftRequired={question.isRequired}>
                                                                <FieldRow>
                                                                    <FieldSlot>
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${question.name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.ID)} 
                                                                            value={answer?.Id}/>
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${question.name}_${DocumentDeliveryTypes.UPLOAD}`, "AnswerType")} 
                                                                            value={question.type}/>
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${question.name}_${DocumentDeliveryTypes.UPLOAD}`, "IsScholarshipDocument")}
                                                                            value={true} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${question.name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.DELIVERY_TYPE)}
                                                                            value={DocumentDeliveryTypes.UPLOAD} />
                                                                        <HiddenInput
                                                                            name={compositeFieldname(`${question.name}_${DocumentDeliveryTypes.UPLOAD}`, AnswerTypeDocumentProps.IS_SELECTED)}
                                                                            value={true} />
                                                                        <ScholarshipUploader name={`${question.name}_${DocumentDeliveryTypes.UPLOAD}`}></ScholarshipUploader>
                                                                    </FieldSlot>
                                                                </FieldRow>
                                                            </FieldSection>
                                                        );
                                                    default:
                                                        break;
                                                }
                                                return (
                                                    <div style={{ marginTop: "2rem" }}>
                                                        {fieldComponent}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>


                                    <div
                                        container
                                        className="grant-action-buttons"
                                        marginTop="1rem"
                                    >

                                        <div>                                            
                                            <a href={`/additional-scholarship-info/${activeYear}#section2`} className='button' >Return to list</a>
                                            <Button
                                                loading={saveLoading}
                                                name="Save"
                                                label="Save"
                                                type="submit"
                                                onClick={handleSubmit(onSubmit, onError)}
                                            />
                                            {appInfo.isStarted && appInfo.isRemovable &&
                                                <Button
                                                    loading={saveLoading}
                                                    name="Remove"
                                                    label="Remove"
                                                    onClick={handleRemove}
                                                />
                                            }
                                            <Button
                                                name="Cancel"
                                                label="Cancel"
                                                variant="text"
                                                onClick={handleCancel}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default Scholarship;
