

/**
 * wraps and interprets result dtos received after adding/deleting/etc an answer
 */
class AnswerUpdateResult {
    
    
    /**
     * dto wrapping constructor
     * @param {Array<*>} data 
     */
    constructor(data) {
        this._data = data;
    }

    get isAwardImpacted() {
        return this._hasImpact("Award");
    }

    get isDeadlineImpacted() {
        return this._hasImpact("Deadline");
    }

    get isOptionsImpacted() {
        return this._hasImpact("Options");
    }

    /**
     * sometimes we get results as arrays-of-arrays, and sometimes just as plain old arrays. This just allows us to work with whatever random
     * type of results are thrown at us.
     */
    get flattenedResults(){
        const results = [];
        for (var entry of this._data){
            if (Array.isArray(entry))
                for (var item of entry){
                    results.push(item);
                }
            else
                results.push(entry);
        }
        return results;
    }

    _hasImpact(impact){
        return this.flattenedResults.some(r => r.Impacts?.some(i => i === impact) === true);       
    }


}

export default AnswerUpdateResult